<template>
    <v-row>
        <v-col cols="12">
            <v-card>
                <v-toolbar color="primary" dark flat height="auto">
                    <v-toolbar-title class="py-2">Informações principais</v-toolbar-title>
                    <v-spacer />
                    <v-btn to="/usuario/grupo" exact icon><v-icon>mdi-arrow-left</v-icon></v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <p class="text--primary mb-2"><strong>Grupo:</strong> {{registro.grupo}}</p>
                            <p class="text--primary mb-2"><strong>ID:</strong> {{registro.id_grupo}}</p>
                            <p class="text--primary mb-2"><strong>Usuários:</strong> {{registro.usuario_total}}</p>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="12">
            <v-card><UsuarioListar :filtro="{id_grupo : id_grupo}"/></v-card>
        </v-col>
    </v-row>
</template>

<script>

import UsuarioListar from "@/Views/Usuario/Component/UsuarioListar"
import axios from "axios"
import {mapState} from "vuex"

export default {
    name: "UsuarioGrupoGerenciar",
    components: {UsuarioListar},
    props : ['id_grupo'],
    data() {
        return {
            tab : null,
            registro : {}
        }
    },
    computed : {
        ...mapState(['baseUrl'])
    },
    methods : {
        get() {
            return axios.post(`${this.baseUrl}usuario/get_grupo`, {id_grupo : this.id_grupo}).then( (res) => {

                this.registro = res.data
            })
        }
    },
    activated() {
        this.get()
    }
}
</script>

<style scoped>

</style>
